import Vue from 'vue';
import store from '../store';

// Import Vue Router
import Router from 'vue-router';

Vue.use(Router);

// Route Lists
const routes = [
  // Anonymous User Routes
  {
    path: '/',
    name: 'anonymousRoute-home',
    component: () => import('../pages/Home'),
  },
  {
    path: '/design/list',
    name: 'anonymousRoute-design-list',
    component: () => import('../pages/Design-List'),
  },
  {
    path: '/design/detail/:id',
    name: 'anonymousRoute-design-detail',
    component: () => import('../pages/Design-Detail'),
  },
  {
    path: '/lp',
    name: 'anonymousRoute-lp',
    component: () => import('../pages/Lp'),
  },
  {
    path: '/image',
    name: 'anonymousRoute-image',
    component: () => import('../pages/Image'),
  },
  {
    path: '/login',
    name: 'anonymousRoute-login',
    component: () => import('../pages/Login'),
  },
  {
    path: '/admin/login',
    name: 'anonymousRoute-admin-login',
    component: () => import('../pages/Admin-Login'),
  },
  {
    path: '/register',
    name: 'anonymousRoute-register',
    component: () => import('../pages/Register'),
  },
  {
    path: '/register/profile',
    name: 'anonymousRoute-register-profile',
    component: () => import('../pages/Register-Profile'),
  },
  {
    path: '/register/questionnaire',
    name: 'anonymousRoute-register-questionnaire',
    component: () => import('../pages/Register-Questionnaire'),
  },
  {
    path: '/register/success',
    name: 'anonymousRoute-register-success',
    component: () => import('../pages/Register-Success'),
  },
  {
    path: '/reset-password',
    name: 'anonymousRoute-reset-password',
    component: () => import('../pages/Reset-Password'),
  },
  {
    path: '/reset-password/email-sent-success',
    name: 'anonymousRoute-reset-password-email-sent-success',
    component: () => import('../pages/Reset-Password-Email-Sent-Success'),
  },
  {
    path: '/reset-password/update',
    name: 'anonymousRoute-reset-password-update',
    component: () => import('../pages/Reset-Password-Update'),
  },
  {
    path: '/reset-password/update-success',
    name: 'anonymousRoute-reset-password-update-success',
    component: () => import('../pages/Reset-Password-Update-Success'),
  },
  {
    path: '/terms',
    name: 'anonymousRoute-terms-use',
    component: () => import('../pages/Terms-Use'),
  },
  {
    path: '/privacy-policy',
    name: 'anonymousRoute-privacy-policy',
    component: () => import('../pages/Privacy-Policy'),
  },
  {
    path: '/inquiry',
    name: 'anonymousRoute-inquiry',
    component: () => import('../pages/Inquiry'),
  },

  // End User Routes
  {
    path: '/dashboard',
    name: 'userRoute-dashboard',
    component: () => import('../pages/customer/Dashboard'),
  },
  {
    path: '/profile',
    redirect: {
      name: 'userRoute-profile-user-info',
    },
    name: 'userRoute-profile',
    component: () => import('../pages/customer/Profile'),
    children: [
      {
        path: 'info',
        name: 'userRoute-profile-user-info',
        component: () => import('../pages/customer/Profile-User-Info'),
      },
      {
        path: 'email-update',
        name: 'userRoute-profile-email-update',
        component: () => import('../pages/customer/Profile-Email-Update'),
      },
      {
        path: 'password-reset',
        name: 'userRoute-profile-password-reset',
        component: () => import('../pages/customer/Profile-Password-Update'),
      },
    ],
  },
  {
    path: '/staging',
    redirect: {
      name: 'userRoute-staging-new-request',
    },
    name: 'userRoute-staging',
    component: () => import('../pages/customer/Staging'),
    children: [
      {
        path: 'select-new-request',
        name: 'userRoute-staging-select-survice',
        component: () => import('../pages/customer/Staging-Select-Survice'),
      },
      {
        path: 'digital-renovation-new-request',
        name: 'userRoute-digital-renovation-new-request',
        component: () => import('../pages/customer/Digital-Renovation-New-Request'),
      },
      {
        path: 'delete-new-request',
        name: 'userRoute-delete-new-request',
        component: () => import('../pages/customer/Delete-New-Request'),
      },
      {
        path: 'office-new-request',
        name: 'userRoute-office-new-request',
        component: () => import('../pages/customer/Office-New-Request'),
      },
      {
        path: 'new-request',
        name: 'userRoute-staging-new-request',
        component: () => import('../pages/customer/Staging-New-Request'),
      },
      {
        path: 'new-360-request',
        name: 'userRoute-staging-new-360-request',
        component: () => import('../pages/customer/Staging-New-360-Request'),
      },
      {
        path: 'pending',
        name: 'userRoute-staging-pending',
        component: () => import('../pages/customer/Staging-Pending'),
      },
      {
        path: 'pending/:id',
        name: 'userRoute-staging-pending-view',
        component: () => import('../pages/customer/Staging-View-Details'),
      },
      {
        path: 'delivered',
        name: 'userRoute-staging-delivered',
        component: () => import('../pages/customer/Staging-Delivered'),
      },
      {
        path: 'delivered/:id',
        name: 'userRoute-staging-delivered-view',
        component: () => import('../pages/customer/Staging-View-Details'),
      },
      {
        path: 'delivered/correction/:id',
        name: 'userRoute-staging-delivered-correction-view',
        component: () =>
          import('../pages/customer/Staging-Delivered-Correction-View'),
      },
      {
        path: 'confirmed',
        name: 'userRoute-staging-confirmed',
        component: () => import('../pages/customer/Staging-Confirmed'),
      },
      {
        path: 'confirmed/:id',
        name: 'userRoute-staging-confirmed-view',
        component: () => import('../pages/customer/Staging-View-Details'),
      },
      {
        path: 'billing-history',
        name: 'userRoute-billing-history',
        component: () => import('../pages/customer/Billing-History'),
      },
      {
        path: 'billing-receipt',
        name: 'userRoute-billing-receipt',
        component: () => import('../pages/customer/Billing-Receipt'),
      },
      {
        path: 'order-details/:id',
        name: 'userRoute-staging-order-details',
        component: () => import('../pages/customer/Staging-View-Details'),
      },
      {
        path: 'design-list',
        name: 'userRoute-staging-design-list',
        component: () => import('../pages/customer/Staging-Design-List'),
      },
      {
        path: 'shooting-request',
        name: 'userRoute-shooting-request',
        component: () => import('../pages/customer/Shooting-Request'),
      },
      {
        path: 'design-detail/:id',
        name: 'userRoute-staging-design-detail',
        component: () => import('../pages/customer/Staging-Design-Detail'),
      },
    ],
  },

  // Admin Routes
  {
    path: '/admin/dashboard',
    name: 'adminRoute-dashboard',
    component: () => import('../pages/admin/Dashboard'),
  },
  {
    path: '/admin/profile',
    redirect: {
      name: 'adminRoute-profile-user-info',
    },
    name: 'adminRoute-profile',
    component: () => import('../pages/admin/Profile'),
    children: [
      {
        path: 'info',
        name: 'adminRoute-profile-user-info',
        component: () => import('../pages/admin/Profile-User-Info'),
      },
      {
        path: 'email-update',
        name: 'adminRoute-profile-email-update',
        component: () => import('../pages/admin/Profile-Email-Update'),
      },
      {
        path: 'password-reset',
        name: 'adminRoute-profile-password-reset',
        component: () => import('../pages/admin/Profile-Password-Update'),
      },
    ],
  },
  {
    path: '/admin/users',
    redirect: {
      name: 'adminRoute-users-list',
    },
    name: 'adminRoute-users',
    component: () => import('../pages/admin/Users'),
    children: [
      {
        path: 'list',
        name: 'adminRoute-users-list',
        component: () => import('../pages/admin/Users-List'),
      },
      {
        path: 'info/:id',
        name: 'adminRoute-users-info',
        component: () => import('../pages/admin/Users-Info'),
      },
      {
        path: 'edit/:id',
        name: 'adminRoute-users-edit',
        component: () => import('../pages/admin/Users-Edit'),
      },
      {
        path: 'create',
        name: 'adminRoute-users-create',
        component: () => import('../pages/admin/Users-Create'),
      },
      {
        path: 'order-history/:id',
        name: 'adminRoute-users-order-history',
        component: () => import('../pages/admin/Users-Order-History-List'),
      },
      {
        path: 'order-history/view/:id',
        name: 'adminRoute-users-order-history-view',
        component: () => import('../pages/admin/Staging-View'),
      },
      {
        path: 'billing-history/:id',
        name: 'adminRoute-users-billing-history',
        component: () => import('../pages/admin/Users-Billing-History'),
      },

    ],
  },
  {
    path: '/admin/inquiry',
    redirect: {
      name: 'adminRoute-inquiry-list',
    },
    name: 'adminRoute-inquiry',
    component: () => import('../pages/admin/Inquiry'),
    children: [
      {
        path: 'list',
        name: 'adminRoute-inquiry-list',
        component: () => import('../pages/admin/Inquiry-List'),
      },
      {
        path: 'view/:id',
        name: 'adminRoute-inquiry-view',
        component: () => import('../pages/admin/Inquiry-View'),
      },
    ],
  },
  {
    path: '/admin/staging',
    redirect: {
      name: 'adminRoute-staging-list',
    },
    name: 'adminRoute-staging',
    component: () => import('../pages/admin/Staging'),
    children: [
      {
        path: 'list',
        name: 'adminRoute-staging-list',
        component: () => import('../pages/admin/Staging-List'),
      },
      {
        path: 'view/:id',
        name: 'adminRoute-staging-view',
        component: () => import('../pages/admin/Staging-View'),
      },
      {
        path: 'correction/:id',
        name: 'adminRoute-staging-correction',
        component: () => import('../pages/admin/Staging-Delivered-Correction'),
      },
    ],
  },
  {
    path: '/admin/invoice-payment',
    redirect: {
      name: 'adminRoute-invoice-payment-list',
    },
    name: 'adminRoute-invoice-payment',
    component: () => import('../pages/admin/Invoice-Payment'),
    children: [
      {
        path: 'list',
        name: 'adminRoute-invoice-payment-list',
        component: () => import('../pages/admin/Invoice-Payment-List'),
      },
      {
        path: 'view/:id',
        name: 'adminRoute-invoice-payment-view',
        component: () => import('../pages/admin/Invoice-Payment-View'),
      },
      {
        path: 'correction/:id',
        name: 'adminRoute-invoice-correction',
        component: () => import('../pages/admin/Staging-Delivered-Correction'),
      },
    ],
  },
  {
    path: '/admin/change-time',
    redirect: {
      name: 'adminRoute-change-time-list',
    },
    name: 'adminRoute-change-time',
    component: () => import('../pages/admin/Change-Time'),
    children: [
      {
        path: 'list',
        name: 'adminRoute-change-time-list',
        component: () => import('../pages/admin/Change-Time-List'),
      },
    ],
  },
  {
    path: '/admin/maintenance',
    name: 'adminRoute-maintenance',
    component: () => import('../pages/admin/Maintenance'),
  },
  {
    path: '/admin/approval',
    redirect: {
      name: 'adminRoute-approval-list',
    },
    name: 'adminRoute-approval',
    component: () => import('../pages/admin/Staging'),
    children: [
      {
        path: 'list',
        name: 'adminRoute-approval-list',
        component: () => import('../pages/admin/Approval-List'),
      },
      {
        path: 'view/:id',
        name: 'adminRoute-approval-view',
        component: () => import('../pages/admin/Approval-View'),
      },
    ],
  },
  {
    path: '/admin/coupon',
    redirect: {
      name: 'adminRoute-coupon-list',
    },
    name: 'adminRoute-coupon',
    component: () => import('../pages/admin/Coupon'),
    children: [
      {
        path: 'list',
        name: 'adminRoute-coupon-list',
        component: () => import('../pages/admin/Coupon-List'),
      },
      {
        path: 'edit/:id',
        name: 'adminRoute-coupon-edit',
        component: () => import('../pages/admin/Coupon-Edit'),
      },
      {
        path: 'create',
        name: 'adminRoute-coupon-create',
        component: () => import('../pages/admin/Coupon-Create'),
      },
      {
        path: 'correction/:id',
        name: 'adminRoute-staging-correction',
        component: () => import('../pages/admin/Staging-Delivered-Correction'),
      },
    ],
  },

  // Error pages catch routes
  {
    path: '/401',
    name: 'errorRoute-401',
    component: () => import('../pages/error/401'),
  },
  {
    path: '/500',
    name: 'errorRoute-500',
    component: () => import('../pages/error/500'),
  },
  {
    // Should always be last among all pages
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../pages/error/404'),
  },
];

const appRouter = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    };
  },
});

// Import Services
import { globalService_GetCurrentUserInfo } from '../services/global';

appRouter.beforeEach(async (to, from, next) => {
  console.clear();

  store.state.topPageLoader = true;

  store.state.currentRoute = to.name;

  let _accessToken = localStorage.getItem('_accessToken');

  if (_accessToken !== null) {
    globalService_GetCurrentUserInfo({ token: _accessToken })
      .then((response) => {
        let userData = response.data.result;
        let role = userData.user_role.role_id;

        store.dispatch('setUserData', userData);

        store.state.isLoggedIn = true;

        // End User State
        if (store.state.isLoggedIn === true && role === 4) {
          let unauthorizedRoute = [
            'anonymousRoute-home',
            'anonymousRoute-login',
            'anonymousRoute-admin-login',
            'anonymousRoute-register',
            'anonymousRoute-reset-password',
            'anonymousRoute-reset-password-email-sent-success',
            'anonymousRoute-reset-password-update',
            'anonymousRoute-reset-password-update-success',
            'adminRoute-dashboard',
            'adminRoute-profile',
            'adminRoute-profile-user-info',
            'adminRoute-profile-email-update',
            'adminRoute-profile-password-reset',
            'adminRoute-users',
            'adminRoute-users-list',
            'adminRoute-users-info',
            'adminRoute-users-edit',
            'adminRoute-users-create',
            'adminRoute-users-order-history',
            'adminRoute-users-order-history-view',
            'adminRoute-inquiry',
            'adminRoute-inquiry-list',
            'adminRoute-inquiry-view',
            'adminRoute-staging',
            'adminRoute-staging-list',
            'adminRoute-staging-view',
            'adminRoute-change-time',
            'adminRoute-change-time-list',
            'adminRoute-Invoice-payment',
            'adminRoute-invoice-payment-list',
            'adminRoute-invoice-payment-view',
            'adminRoute-approval',
            'adminRoute-approval-list',
            'adminRoute-approval-view',
            'adminRoute-maintenance',
            'adminRoute-coupon',
            'adminRoute-coupon-list',
            'adminRoute-coupon-edit',
            'adminRoute-coupon-create',
          ];

          if (unauthorizedRoute.includes(to.name)) {
            next({ name: 'userRoute-dashboard' });

            store.state.topPageLoader = false;
          }

          next();

          store.state.topPageLoader = false;

          console.log('Current-State', store.state);
        }

        // Admin User State
        if (store.state.isLoggedIn === true && role !== 4) {
          let unauthorizedRoute = [
            'anonymousRoute-login',
            'anonymousRoute-admin-login',
            'anonymousRoute-register',
            'anonymousRoute-reset-password',
            'anonymousRoute-reset-password-email-sent-success',
            'anonymousRoute-reset-password-update',
            'anonymousRoute-reset-password-update-success',
            'anonymousRoute-register-profile',
            'anonymousRoute-register-questionnaire',
            'anonymousRoute-register-success',
            'userRoute-dashboard',
            'userRoute-profile',
            'userRoute-profile-user-info',
            'userRoute-profile-email-update',
            'userRoute-profile-password-reset',
            'userRoute-staging',
            'userRoute-staging-new-request',
            'userRoute-staging-new-360-request',
            'userRoute-staging-pending',
            'userRoute-staging-pending-view',
            'userRoute-staging-delivered',
            'userRoute-staging-delivered-view',
            'userRoute-staging-delivered-correction-view',
            'userRoute-staging-confirmed-view',
            'userRoute-staging-confirmed',
            'userRoute-billing-history',
            'userRoute-staging-design-list',
            'userRoute-shooting-request',
          ];

          if (unauthorizedRoute.includes(to.name)) {
            next({ name: 'adminRoute-dashboard' });

            store.state.topPageLoader = false;
          }

          next();

          store.state.topPageLoader = false;

          console.log('Current-State', store.state);
        }
      })
      .catch((error) => {
        console.log(error);

        store.state.isLoggedIn = false;
        store.state.modalLoader = true;
        store.state.modalLoaderMessage = 'サインアウト中';

        setTimeout(() => {
          store.state.modalLoader = false;

          localStorage.removeItem('_accessToken');

          next({ name: 'anonymousRoute-home' });

          store.state.topPageLoader = false;
        }, 1000);
      });
  } else {
    // Anonymous User State
    if (store.state.isLoggedIn === false) {
      let unauthorizedRoute = [
        'anonymousRoute-register-profile',
        'anonymousRoute-register-questionnaire',
        'anonymousRoute-register-success',
        'userRoute-dashboard',
        'userRoute-profile',
        'userRoute-profile-user-info',
        'userRoute-profile-email-update',
        'userRoute-profile-password-reset',
        'userRoute-staging',
        'userRoute-staging-new-request',
        'userRoute-staging-new-360-request',
        'userRoute-staging-pending',
        'userRoute-staging-pending-view',
        'userRoute-staging-delivered',
        'userRoute-staging-delivered-view',
        'userRoute-staging-delivered-correction-view',
        'userRoute-staging-confirmed-view',
        'userRoute-staging-confirmed',
        'userRoute-billing-history',
        'userRoute-staging-design-list',
        'userRoute-shooting-request',
        'adminRoute-dashboard',
        'adminRoute-profile',
        'adminRoute-profile-user-info',
        'adminRoute-profile-email-update',
        'adminRoute-profile-password-reset',
        'adminRoute-users',
        'adminRoute-users-list',
        'adminRoute-users-info',
        'adminRoute-users-edit',
        'adminRoute-users-create',
        'adminRoute-inquiry',
        'adminRoute-inquiry-list',
        'adminRoute-inquiry-view',
        'adminRoute-staging',
        'adminRoute-staging-list',
        'adminRoute-staging-view',
        'adminRoute-change-time',
        'adminRoute-change-time-list',
        'adminRoute-Invoice-payment',
        'adminRoute-invoice-payment-list',
        'adminRoute-invoice-payment-view',
        'adminRoute-approval',
        'adminRoute-approval-list',
        'adminRoute-approval-view',
        'adminRoute-maintenance',
        'adminRoute-coupon',
        'adminRoute-coupon-list',
        'adminRoute-coupon-edit',
        'adminRoute-coupon-create',
        'adminRoute-users-order-history',
        'adminRoute-users-order-history-view',
      ];

      if (unauthorizedRoute.includes(to.name)) {
        next({ name: 'anonymousRoute-home' });

        store.state.topPageLoader = false;
      }

      next();

      store.state.topPageLoader = false;
    }
  }
});

export default appRouter;
